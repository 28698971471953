import React from 'react'


const Categories = () => {


  return (
    <section className="what-make tokenize categ pt-100 mb-0">   
          <div className="container">
            <h4 className="heading-h2 text-center">Opensea Clone <span className='bluecolor'>Supported NFT Collections</span></h4>
              <div className='d-flex flex-wrap justify-content-center ind-std'>
                  <p>Art</p>
                  <p>Gaming</p>
                  <p>Videos</p>
                  <p>Music</p>
                  <p>Profile picture NFTs</p>
                  <p>Photography</p>
              </div>
          </div>
      </section>
  )
}

export default Categories