import { StaticImage } from 'gatsby-plugin-image'
import React,{useState, useEffect} from 'react'


const TopFeatures =()=> {

  const [isDesktop, setIsDesktop] = useState(false);

  useEffect(() => {
    const updateMedia = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", updateMedia);
    updateMedia(); // Check initial size

    return () => window.removeEventListener("resize", updateMedia);
  }, []);

    return (
      <section className="topfeaturez pt-100 mb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
              <h2 className="heading-h2">Opensea Clone Script <span className="bluecolor">Features</span></h2>
            </div>
          </div>
          <div className="row table-content orderflex marbot-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Store Front</h3>
                <p className="pharagraph">Our OpenSea script comes with an exceptional storefront design to showcase and list a variety of NFTs on the front page.
                </p>
              </div>
            </div>
            {isDesktop && (
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/store-front.png"
                      alt="Store Front"
                      width={536}
                    />
            </div>
            )}
          </div>
          <div className="row table-content orderflex marbot-0">
          {isDesktop && (
            <div className="col-md-12 col-lg-6 order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/auction.png"
                      alt="Auction"
                      width={536}
                    />
            </div>
            )}
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">Auction</h3>
                <p className="pharagraph">With our auction feature, creators can sell their NFTs on the NFT website through a bidding process.
                </p>
              </div>
            </div>
          </div>
          <div className="row table-content orderflex marbot-0">
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-left" >
                <h3 className="heading-h4">Listing</h3>
                <p className="pharagraph">We develop NFT Websites where creators can efficiently list any top NFTs including Pudgy Penguins in the platform in a hassle-free manner.</p>
              </div>
            </div>
            {isDesktop && (
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/efficient-listing.png"
                      alt="Efficient Listing"
                      width={536}
                    />
            </div>
            )}
          </div>
          <div className="row table-content orderflex marbot-0">
          {isDesktop && (
            <div className="col-md-12 col-lg-6 right-side order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/minting.png"
                      alt="NFT Minting"
                      width={536}
                    />
            </div>
          )}
            <div className="col-md-12 col-lg-6 order2">
              <div className="top-right" >
                <h3 className="heading-h4">NFT Minting</h3>
                <p className="pharagraph">Our OpenSea like NFT platform is built with the minting feature allowing users to store ownership of digital collectibles on any blockchain. 
                </p>
              </div>
            </div>
      
          </div>
          <div className="row table-content orderflex mb-0">
            <div className="col-md-12 col-lg-6 order2 marbot-0 ">
              <div className="top-left" >
                <h3 className="heading-h4">Secure IPFS Storage</h3>
                <p className="pharagraph">Our esteemed data security includes the protection of NFTs with an IPFS (InterPlanetary File System) storage system.
                </p>
              </div>
            </div>
            {isDesktop && (
            <div className="col-md-12 col-lg-6  order1">
            <StaticImage
                      src="https://coinsclone.mo.cloudinary.net/images/opensea-new/simplified-architecture.png"
                      alt="Secure IPFS Storage"
                      width={536}
                    />
            </div>
            )}
          </div>
        </div>
      </section>
    )
}

export default TopFeatures