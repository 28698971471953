import React from 'react'



class Whatis extends React.Component {


  render() {

    return (
      <section className="whatis mb-0 py-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="shadoww">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12 col-12 text-center">
                <h2 className="heading-h2">Create NFT Marketplace Like <span className="bluecolor">OpenSea</span></h2>
              </div>
            </div>
            <div className="text-center">
              <p className="pharagraph">Launching an NFT Marketplace like OpenSea is a great idea, but choosing the right development model matters the most. You can choose between starting from scratch or using white-label solutions. At Coinsclone, we bring you the "OpenSea Clone Script"— A Tailor-made solution to kickstart your own NFT Marketplace like OpenSea. Coinsclone’s White Label OpenSea Clone Script is a pre-built, customizable NFT Marketplace script that can be deployed instantly to start an OpenSea-like NFT Marketplace, allowing users to trade, sell, and buy NFTs on various blockchain networks.
              </p>
              <p className="pharagraph mb-0">Our OpenSea Clone Script includes comprehensive features such as storefronts, NFT minting, and integrated wallets, ensuring a seamless decentralized experience across blockchain networks. Also, our OpenSea Clone provides a powerful, secure platform that can be tailored to your brand’s needs. With advanced technologies and budget-friendly solutions, you can start your NFT Marketplace like OpenSea, enjoying the benefits of a tested and scalable product while minimizing development time and costs.</p>
            </div>
          </div>
        </div>
        </div>
      </section>
    )
  }
}

export default Whatis