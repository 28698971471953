import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'



const TechStack = () => {


  return (
    <div className='pt-100'>
      <section className="technostack mb-0">
        <div className='gray-bg'>
        <div className="container">
          <div className="row table-content">
            <div className="col-md-12 col-lg-6 text-left">
              <h5 className="heading-h2">Opensea Clone Script <span className="bluecolor">Tech Stack</span></h5>
              <p className="pharagraph">As blockchain pioneers, we have used the latest advanced technologies to build this OpenSea clone software to make it swifter and productive for entrepreneurs from around the globe.
              </p>
            </div>
            <div className="col-md-12 col-lg-6">
                <StaticImage 
                    src="https://coinsclone.mo.cloudinary.net/images/whitelabel/technologies-stock.png"
                    alt="Technologies stock"
                    className='logostck'
                    width={573}
                  />
            </div>
          </div>
        </div>
        </div>
      </section>
      </div>
  )
}

export default TechStack