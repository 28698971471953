import React from "react";
import Slider from "react-slick";
import ButtonComponent from '../ButtonComponent/ButtonComponent'
import { StaticImage } from 'gatsby-plugin-image';




  const Banner = () => {


    const settings = {
      dots: false,
      infinite: true,
      speed: 2500,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 1000
    };

    return (
      <section className="banner">
        <div className="container">
          <div className="row table-content">
            <div className="col-lg-5 col-md-12 col-sm-12 col-12 left-banner">
              <div className="mw600">
                <h1 className="main-heading"><span className="bluecolor">OpenSea Clone</span> Script</h1>
                <p className="pharagraph">Are you looking to create a top-notch NFT marketplace like OpenSea? Get the best OpenSea Clone Script from coinsclone - A White Label Solution to kickstart your own NFT Marketplace with unlimited customization and premium features.</p>
                <div className="cta-banner">
                <ButtonComponent />
                <a href="https://calendly.com/mathi-pixelweb/" className="bluebtn">Book a calendly meeting</a>
                </div>
              </div>
            </div>
              <div className="col-lg-7 col-md-12 col-sm-12 col-12 right-banner mobile-hide">
              <div className="screen">
                  <StaticImage
                    src="https://coinsclone.mo.cloudinary.net/images/binance-new/laptop-binance.png"
                    alt="OpenSea clone laptop front view"
                    width={876}
                  />
                  <div className="outder">
                    <Slider {...settings}>
                      <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-script-screen-1.png"
                        alt="OpenSea clone script slider1"
                        width={647}
                      />
                      <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-script-screen-2.png"
                        alt="OpenSea clone script slider2"
                        width={647}
                      />
                      <StaticImage
                        src="https://coinsclone.mo.cloudinary.net/images/opensea-new/opensea-clone-script-screen-3.png"
                        alt="OpenSea clone script slider3"
                        width={647}
                      />
                    </Slider>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </section>
    )
}

export default Banner;