import React from 'react'



const PopuparWeb =()=> {

    return (
      <section className="secureof lucrative centeralign pt-100 mb-0">
        <div className="container">
        <h4 className="heading-h2 text-center"><span className="bluecolor heading-h3">Popular Web3 Wallets</span> Supported by Our OpenSea Clone</h4>
          <div className="factor">
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/metamask-wallet.png" alt="MetaMask Wallet" />
              <span>MetaMask Wallet</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/coinbase-wallet.png" alt="Coinbase Wallet" /><span>Coinbase Wallet
</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/binance.png" alt="BNB Wallet" /><span>BNB Wallet</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/trust-wallet.png" alt="Trust Wallet" /><span>Trust Wallet</span></p>
            </div>
            <div className="revenue">
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/phantom-wallet.png" alt="Phantom Wallet" /><span>Phantom Wallet</span></p>
              <p><img width="55px" height="55px" src="https://coinsclone.mo.cloudinary.net/images/opensea-new/ether-wallet.png" alt="My ether wallet" /><span>My ether wallet</span></p>
            </div>
          </div>
        </div>
      </section>
    )
}

export default PopuparWeb